import React, { Suspense } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

/**** Preloader & Veritas Pages ****/
const Preloader = React.lazy(() => import("./components/layouts/Preloader"));
const LandingPage = React.lazy(() => import("./components/pages/LandingPage"));
const HuntVault = React.lazy(() => import("./components/pages/HuntVault"));

/**** Lost Socks Hunt ****/
const LostSocksHunt = React.lazy(() =>
  import("./components/pages/LostSocks/LostSocksHunt")
);
const BrownRectangle = React.lazy(() =>
  import("./components/pages/LostSocks/BrownRectangle")
);

/**** Scholarz Hunt ****/
const ScholarzStart = React.lazy(() =>
  import("./components/pages/Scholarz/ScholarzStart")
);
const Scholarz1 = React.lazy(() =>
  import("./components/pages/Scholarz/Scholarz1")
);
const Scholarz2 = React.lazy(() =>
  import("./components/pages/Scholarz/IlluminatioVideri")
);
const Scholarz3 = React.lazy(() =>
  import("./components/pages/Scholarz/VeritasMens")
);
const ScholarzMini = React.lazy(() =>
  import("./components/pages/Scholarz2/Scholarz2")
);

/**** Zom Hunt ****/
const ZomLanding = React.lazy(() =>
  import("./components/pages/Zom/ZomLanding")
);
const Zom1 = React.lazy(() => import("./components/pages/Zom/Zom1"));
const Zom2 = React.lazy(() => import("./components/pages/Zom/Zom2"));

/**** MetaPopits Hunt ****/
const MetaLanding = React.lazy(() =>
  import("./components/pages/MetaPopits/MetaLanding")
);
const MetaStart = React.lazy(() =>
  import("./components/pages/MetaPopits/MetaStart")
);
const Cepheus = React.lazy(() =>
  import("./components/pages/MetaPopits/Cepheus")
);
const Fireworks = React.lazy(() =>
  import("./components/pages/MetaPopits/Fireworks")
);

/**** BATTLEFLY Mini Hunt ****/
const Battlefly = React.lazy(() =>
  import("./components/pages/Battlefly/Battlefly")
);

/**** ZENAPE Mini Hunt ****/
const ZenApe = React.lazy(() => import("./components/pages/ZenApe/ZenApe"));

/**** GODJIRA Hunt ****/
const JiraLanding = React.lazy(() =>
  import("./components/pages/Godjira/JiraLanding")
);
const Godjira = React.lazy(() => import("./components/pages/Godjira/Godjira"));
const Ishiro = React.lazy(() =>
  import("./components/pages/Godjira/IshiroHonda")
);

/**** BRAINDOM Hunt ****/
const Braindom1 = React.lazy(() => import("./components/pages/Braindom/Page1"));
const Braindom2 = React.lazy(() => import("./components/pages/Braindom/Page2"));
const BraindomLanding = React.lazy(() =>
  import("./components/pages/Braindom/BraindomLanding")
);

/**** Veritas Mother Hunt ****/
const Mother1 = React.lazy(() => import("./components/pages/Mother/Mother1"));
const Mother2 = React.lazy(() => import("./components/pages/Mother/Mother2"));

/**** Salty Pirate Hunt ****/
const Salty0 = React.lazy(() =>
  import("./components/pages/SaltyPirates/Page0")
);
const Salty1 = React.lazy(() =>
  import("./components/pages/SaltyPirates/Page1")
);
const Salty2 = React.lazy(() =>
  import("./components/pages/SaltyPirates/Page2")
);
const Salty3 = React.lazy(() =>
  import("./components/pages/SaltyPirates/Page3")
);
const Salty4 = React.lazy(() =>
  import("./components/pages/SaltyPirates/Page4")
);
const Salty5 = React.lazy(() =>
  import("./components/pages/SaltyPirates/Page5")
);

function App() {
  return (
    <Router>
      <Suspense fallback={<div></div>}>
        <Preloader />
        <Switch>
          {/* Veritas Pages */}
          <Route exact path="/" component={LandingPage} />
          <Route path="/home" component={LandingPage} />
          <Route path="/hunt-vault" component={HuntVault} />
          {/* Lost Socks Hunt */}
          <Route path="/lostsockshunt" component={LostSocksHunt} />
          <Route
            path="/brownrectangleshieldflowersoak"
            component={BrownRectangle}
          />
          {/* Scholarz Hunt */}
          <Route path="/scholarz" component={ScholarzStart} />
          <Route path="/scholarz-hunt" component={Scholarz1} />
          <Route path="/illuminatiovideri" component={Scholarz2} />
          <Route path="/veritasmens" component={Scholarz3} />
          <Route path="/scholarz-mini" component={ScholarzMini} />
          {/* MetaPopits Hunt Hunt */}
          <Route path="/metapopit" component={MetaLanding} />
          <Route path="/metapopit-start" component={MetaStart} />
          <Route path="/cepheus" component={Cepheus} />
          <Route path="/fireworks-galaxy" component={Fireworks} />
          {/* Battlefly Mini Hunt */}
          <Route path="/battlefly" component={Battlefly} />
          {/* ZenApe Mini Hunt */}
          <Route path="/zenape" component={ZenApe} />
          {/* Godjira Hunt */}
          <Route path="/jira-landing" component={JiraLanding} />
          <Route path="/godjira" component={Godjira} />
          <Route path="/ishiro-honda" component={Ishiro} />
          {/* Braindom Hunt */}
          <Route path="/braindom" component={Braindom1} />
          <Route path="/braindom-finale" component={Braindom2} />
          <Route path="/braindom-start" component={BraindomLanding} />
          {/* ZOM Hunt */}
          <Route path="/zom-landing" component={ZomLanding} />
          <Route path="/zom-start" component={Zom1} />
          <Route path="/zom-bip39" component={Zom2} />
          {/* Mother Hunt (Pre-Mint) */}
          <Route path="/mother-1" component={Mother1} />
          <Route path="/mother-2" component={Mother2} />
          {/* SaltyPirate */}
          <Route path="/salty-start" component={Salty0} />
          <Route path="/salty-1" component={Salty1} />
          <Route path="/saltyinferno" component={Salty2} />
          <Route path="/salty-railway" component={Salty3} />
          <Route
            path="/thefinalrestingplaceofthesaltiestcaptain"
            component={Salty4}
          />
          <Route path="/maldonssecretlogs" component={Salty5} />
        </Switch>
      </Suspense>
    </Router>
  );
}

export default App;
